<template>
  <!-- Table Container Card -->
  <div>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <!-- Search Content-->
          <b-col
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <small
                v-if="content.id"
                class="text-muted"
              >
                <b-badge
                  pill
                  variant="light-primary"
                  class="mr-1 text-capitalize small"
                >
                  {{ content.contentType.name }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-warning"
                  class="mr-1 text-capitalize small"
                >
                  {{ content.contentFrequency.name }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-info"
                  class="mr-1 text-capitalize small"
                >
                  {{ content.contentStatus.name }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-secondary"
                  class="mr-1 text-capitalize small"
                >
                  {{ formatDateTime(content.start) }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-secondary"
                  class="mr-1 text-capitalize small"
                >
                  {{ formatDateTime(content.end) }}
                </b-badge>
              </small>
              <v-select
                v-model="content"
                :options="allContents"
                label="name"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_CONTENT')"
                @input="handleContentSelectChange()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <section id="feather-icons">
      <div
        id="icons-container"
        class="d-flex flex-wrap"
      >
        <b-card
          v-for="charity in charities"
          :key="charity.id"
          v-b-tooltip.hover.top="charity.name"
          class="icon-card cursor-pointer text-center mb-2 mx-50"
          @click="addorDeleteContentCharity(charity)"
        >
          <div class="icon-wrapper">
            <b-img
              :src="charity.media.url"
              alt="avatar img"
              width="90px"
              height="90px"
            />
          </div>
          <b-card-text class="icon-name text-truncate mb-0 mt-1">
            <feather-icon
              v-if="charity.isGifted && canEditCharity"
              class="text-primary"
              icon="GiftIcon"
              size="24"
            />
            <feather-icon
              v-if="charity.isGifted && !canEditCharity"
              class="text-success"
              icon="GiftIcon"
              size="24"
            />
          </b-card-text>
        </b-card>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardText, VBTooltip, BImg,
  BRow, BCol, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { isContentEditableOrDeletable } from '@/constants/utils'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BCard,
    BCardText,
    vSelect,
    BImg,
    BRow,
    BCol,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixinDate],
  data() {
    return {
      canEditCharity: true,
      allContents: [],
      charities: [],
      content: useJwt.getContent() || {},
    }
  },
  computed: {
    ...mapState('charity', [
      'allCharities',
      'allContentCharities']),
  },
  async created() {
    try {
      await this.fetchAllCharities()
      const response = await this.fetchAllContents()
      if (response && response.data) {
        this.allContents = response.data
        const routeId = router.currentRoute.params.id
        if (routeId) {
          this.content = this.allContents.find(item => item.id === routeId)
        }
        this.handleContentSelectChange()
      }
    } catch (error) {
      this.errorMessage(this.$i18n.t('MESSAGE.PAGE_LOAD_FAILED'))
    }
  },
  methods: {
    ...mapActions('charity', [
      'fetchAllCharities',
      'fetchAllContentCharities',
      'createContentCharity',
      'deleteContentCharity']),
    ...mapActions('content', ['fetchAllContents']),
    fetchContentCharities(id) {
      try {
        this.fetchAllContentCharities(id).then(() => {
          this.mapContentCharity()
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    handleContentSelectChange() {
      if (this.content.id) {
        this.fetchContentCharities(this.content.id)
        useJwt.setContent(this.content)
        this.canEditCharity = isContentEditableOrDeletable(this.content)
      }
    },
    addorDeleteContentCharity(charity) {
      if (this.canEditCharity) {
        if (charity.isGifted) {
          this.deleteContentCharity({ charityId: charity.id, contentId: this.content.id }).then(() => {
            this.successMessage(this.$i18n.t('MESSAGE.CHARITY_DELETED'))
            this.handleContentSelectChange()
          })
        } else {
          const payload = { charityId: charity.id, contentId: this.content.id }
          this.createContentCharity(payload).then(() => {
            this.successMessage(this.$i18n.t('MESSAGE.CHARITY_CREATED'))
            this.handleContentSelectChange()
          })
        }
      }
    },
    mapContentCharity() {
      try {
        this.charities = JSON.parse(JSON.stringify(this.allCharities))
        if (this.allContentCharities.length > 0) {
          this.allContentCharities.forEach(e => {
            const matchingContentCharity = this.charities.find(element => element.id === e.charityId)
            if (matchingContentCharity) {
              matchingContentCharity.ContentCharityId = e.id
              matchingContentCharity.isGifted = true
            }
          })
        }
      } catch (error) {
        this.showErrorMessage()
      }
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
